import md5 from "js-md5";

export let sign = (data) => {
  let key = "bluecat2021"; //签名Key
  let keys = [];
  console.log(data)
  for (let k in data){
    if (k == 'sign' || k == 'undefined' || k === '0' || k === 0 || k == 'null' || k ==null || k === ''){
      continue;
    }
    keys.push(k);
  }
  //排序
  keys.sort();
  console.log(keys)
  let kv = [];
  for (let k of keys){
    if (data[k] == 'undefined' || data[k] === '' || data[k] === '0' || (data[k] === 0 && data[k] !== '0.00') || data[k] == 'null' || data[k] ==null || (typeof data[k]) == 'object'){
      continue;
    }
    kv.push(k + '=' +  unescape(data[k])) //urlencode编码
  }

  let signStr = kv.join('&');
  console.log(signStr)
  signStr = signStr + "&key="+key;
  signStr = md5(signStr)
  return signStr;
}