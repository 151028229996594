import Vue from 'vue'
import App from './App.vue'
import router from './router'
import moment from 'moment';
import "@/plugin/element"
import "normalize.css"
import "element-ui/packages/theme-chalk/src/index.scss";
import http from '@/api/http.js'

import "lib-flexible"

Vue.config.productionTip = false
Vue.prototype.$moment = moment
Vue.prototype.$http = http

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
